<template>
  <div>
    <el-card>
      <el-tag
        size="medium"
        style="
          margin-bottom: 20px;
          line-height: 30px;
          height: 30px;
          text-align: center;
          font-size: 15px;
        "
        >活动名称：{{ title }}</el-tag
      >
      <nav class="out">
        <nav class="input">
          <el-input
            v-model="form_2.keyword"
            size="medium"
            placeholder="手机号，姓名，昵称"
          ></el-input>
        </nav>
        <label
          for=""
          style="font-size: 14px; float: left; transform: translateY(9px)"
          >参与时间：</label
        >
        <nav class="input">
          <el-date-picker
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            v-model="form_2.activityDate"
            style="width: 100%"
            size="medium"
          >
          </el-date-picker>
        </nav>

        <nav class="input" style="width: 340px;">
          <label
            for=""
            style="font-size: 14px; float: left; transform: translateY(9px)"
            >中奖状态：</label
          >
          <el-select v-model="form_2.status" style="width: 200px;">
            <el-option label="全部" value="-1"> </el-option>
            <el-option label="未中奖" value="0"> </el-option>
            <el-option label="中奖" value="1"> </el-option>
          </el-select>
        </nav>

        <nav class="input" style="width: 123px;">
          <el-button @click="reset" size="small">重置</el-button>
          <el-button @click="subimt" type="primary" size="small">{{
            '搜索'
          }}</el-button>
        </nav>
      </nav>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <!-- <el-table-column type="selection"></el-table-column> -->
        <el-table-column prop="id" width="100" label="编号"> </el-table-column>
        <el-table-column prop="nickname" label="姓名名称">
          <template slot-scope="{ row }">
            {{ row.realName || row.nickname }}
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号"> </el-table-column>
        <el-table-column prop="createTime" label="抽奖时间"> </el-table-column>
        <el-table-column prop="awardName" label="奖品"> </el-table-column>
        <el-table-column prop="awardName" label="状态">
          <template slot-scope="{ row }">
            <el-tag type="warning" size="samll">{{
              row.isSend ? '已发放' : '未发放'
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="awardName" label="操作">
          <template slot-scope="{ row }">
            <el-button
              type="text"
              icon="el-icon-s-promotion"
              size="mini"
              style="color:tan;"
              @click="open(row.id)"
              >发放奖品</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="form_2.page"
        :total="total"
        :page-size="form_2.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btn: true,
      list: [],
      form_2: {
        page: 1,
        pageSize: 10,
        keyword: '',
        status: '',
        id: '',
        activityDate: []
      },
      title: '',
      form: {
        id: '',
        linkName: '',
        linkUrl: ''
      },

      total: 0,
      dialogVisible: false,
      dialogVisiblexui: false
    }
  },
  methods: {
    rowClass() {
      return 'background:#F5F7FA;'
    },
    open() {
      this.$confirm('请确认奖品已经发放', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.offeringPrizes()
        })
        .catch(() => {})
    },
    async offeringPrizes(row) {
      let { data: res } = await this.$http.post('/admin/Rotary/awardsend', {
        id: row
      })
      if (res.errorCode == 200) {
        this.$message.success(res.message)
        this.getlist()
      } else {
        this.$message.error(res.message)
      }
    },
    f_status(val) {
      if (val == 0) {
        return '未开始'
      } else if (val == 1) {
        return '进行中'
      } else if (val == 2) {
        return '结束'
      }
    },
    theEditor() {},
    reset() {
      Object.assign(this.$data, this.$options.data.call(this))
      this.getlist()
    },
    getlist() {
      this.form_2.id = this.$route.query.id
      //   console.log(this.$route)
      this.$http
        .get('/admin/Rotary/showData', {
          params: {
            ...this.form_2,
            activityDate: this.form_2.activityDate
              ? this.form_2.activityDate.join('~')
              : ''
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            console.log(res.data.list)
            this.list = res.data.list
            this.total = res.data.totalCount
            // console.log(this.list)
            this.title = res.data.rotary.name
          } else {
            this.$message.error(res.message)
          }
        })
    },
    edit(e) {
      this.form.id = e
      this.dialogVisible = true
      this.$http
        .get('/admin/FriendLink/getById?id=' + e)
        .then(({ data: res }) => {
          this.form.linkName = res.data.linkName
          this.form.linkUrl = res.data.linkUrl
        })
    },
    add() {
      if (this.btn) {
        this.$http
          .post('/admin/FriendLink/edit', this.form)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.getlist()
              this.$message.success(res.message)
              this.dialogVisible = false
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    // this

    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'activity_order_num'
        } else {
          s = 'rl_create_time'
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },
    handleSizeChange(e) {
      this.pagesize = e
      this.getlist()
    },
    handleCurrentChange(e) {
      this.form_2.page = e
      this.getlist()
    },
    subimt() {
      this.form_2.page = 1
      this.getlist()
    }
  },
  created() {
    this.getlist()
  }
}
</script>

<style lang="less" scoped>
.upload {
  width: 200px;
  height: 200px;
}
.skyblue {
  color: skyblue;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
.red {
  color: red;
}
</style>
